import _once from "../../lib/once";
import _jsonpDriver from "./jsonp-driver";
import _ajaxDriver from "./ajax-driver";
import _getUserAgent from "./get-user-agent";
import _isHttp from "./is-http";
var exports = {};
var ajaxIsAvaliable;
var once = _once;
var JSONPDriver = _jsonpDriver;
var AJAXDriver = _ajaxDriver;
var getUserAgent = _getUserAgent;
var isHTTP = _isHttp;
function isAjaxAvailable() {
  if (ajaxIsAvaliable == null) {
    ajaxIsAvaliable = !(isHTTP() && /MSIE\s(8|9)/.test(getUserAgent()));
  }
  return ajaxIsAvaliable;
}
exports = function (options, cb) {
  cb = once(cb || Function.prototype);
  options.method = (options.method || "GET").toUpperCase();
  options.timeout = options.timeout == null ? 60000 : options.timeout;
  options.data = options.data || {};
  if (isAjaxAvailable()) {
    AJAXDriver.request(options, cb);
  } else {
    JSONPDriver.request(options, cb);
  }
};
export default exports;