import * as _isIe2 from "@braintree/browser-detection/is-ie";
var _isIe = "default" in _isIe2 ? _isIe2.default : _isIe2;
import * as _isIe4 from "@braintree/browser-detection/is-ie9";
var _isIe3 = "default" in _isIe4 ? _isIe4.default : _isIe4;
var exports = {};
var isIe = _isIe;
var isIe9 = _isIe3;
exports = {
  isIe: isIe,
  isIe9: isIe9
};
export default exports;