import * as _uuid2 from "@braintree/uuid";
var _uuid = "default" in _uuid2 ? _uuid2.default : _uuid2;
import _querystring from "../../lib/querystring";
var exports = {};
var head;
var uuid = _uuid;
var querystring = _querystring;
var timeouts = {};
function _removeScript(script) {
  if (script && script.parentNode) {
    script.parentNode.removeChild(script);
  }
}
function _createScriptTag(url, callbackName) {
  var script = document.createElement("script");
  var done = false;
  script.src = url;
  script.async = true;
  script.onerror = function () {
    window[callbackName]({
      message: "error",
      status: 500
    });
  };
  script.onload = script.onreadystatechange = function () {
    if (done) {
      return;
    }
    if (!this.readyState || this.readyState === "loaded" || this.readyState === "complete") {
      done = true;
      script.onload = script.onreadystatechange = null;
    }
  };
  return script;
}
function _cleanupGlobal(callbackName) {
  try {
    delete window[callbackName];
  } catch (_) {
    window[callbackName] = null;
  }
}
function _setupTimeout(timeout, callbackName) {
  timeouts[callbackName] = setTimeout(function () {
    timeouts[callbackName] = null;
    window[callbackName]({
      error: "timeout",
      status: -1
    });
    window[callbackName] = function () {
      _cleanupGlobal(callbackName);
    };
  }, timeout);
}
function _setupGlobalCallback(script, callback, callbackName) {
  window[callbackName] = function (response) {
    var status = response.status || 500;
    var err = null;
    var data = null;
    delete response.status;
    if (status >= 400 || status < 200) {
      err = response;
    } else {
      data = response;
    }
    _cleanupGlobal(callbackName);
    _removeScript(script);
    clearTimeout(timeouts[callbackName]);
    callback(err, data, status);
  };
}
function request(options, callback) {
  var script;
  var callbackName = "callback_json_" + uuid().replace(/-/g, "");
  var url = options.url;
  var attrs = options.data;
  var method = options.method;
  var timeout = options.timeout;
  url = querystring.queryify(url, attrs);
  url = querystring.queryify(url, {
    _method: method,
    callback: callbackName
  });
  script = _createScriptTag(url, callbackName);
  _setupGlobalCallback(script, callback, callbackName);
  _setupTimeout(timeout, callbackName);
  if (!head) {
    head = document.getElementsByTagName("head")[0];
  }
  head.appendChild(script);
}
exports = {
  request: request
};
export default exports;